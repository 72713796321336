const React = require("react");
const ReactDOM = require("react-dom");
const retargetEvents = require("react-shadow-dom-retarget-events");
const extractAttributes = require("./extractAttributes");

require("@webcomponents/webcomponentsjs");

module.exports = {
    create: ({
        elementFactory,
        injectCoreStyles,
        tagName,
        observedAttributes = [],
        useShadowDom = true,
        useShadowDomWrapperDiv = false,
    }) => {
        const proto = class extends HTMLElement {
            constructor() {
                super();
                this._callbacks = {};
            }

            _registerCallback(callbackName, callbackFn) {
                if (typeof this._callbacks[callbackName] === "undefined") {
                    this._callbacks[callbackName] = [];
                }
                this._callbacks[callbackName].push(callbackFn);
            }

            _runCallback(callbackName, args) {
                if (typeof this._callbacks[callbackName] === "undefined") {
                    return;
                }
                for (var i = 0; i < this._callbacks[callbackName].length; i++) {
                    this._callbacks[callbackName][i].apply(this, args);
                }
            }

            static get observedAttributes() {
                return observedAttributes;
            }

            connectedCallback() {
                let shadowRoot;
                const webComponentInstance = this;
                let mountPoint = webComponentInstance;

                injectCoreStyles();

                if (useShadowDom) {
                    // Re-assign the webComponentInstance (this) to the newly created shadowRoot
                    shadowRoot = webComponentInstance.attachShadow({ mode: "open" });

                    if (useShadowDomWrapperDiv) {
                        // Re-assign the mountPoint to the newly created "div" element
                        mountPoint = document.createElement("div");
                        shadowRoot.appendChild(mountPoint);
                    } else {
                        mountPoint = shadowRoot;
                    }

                    retargetEvents(shadowRoot);
                }

                (async () => {
                    const element = await elementFactory({
                        attributes: extractAttributes(webComponentInstance),
                        instance: webComponentInstance,
                        registerCallback: (callbackName, callbackFn) => {
                            this._registerCallback(callbackName, callbackFn);
                        },
                        insertStyle: (style) => {
                            const css = document.createElement("style");
                            css.type = "text/css";

                            if (css.styleSheet) {
                                css.styleSheet.cssText = style;
                            } else {
                                css.appendChild(document.createTextNode(style));
                            }

                            if (useShadowDom) {
                                shadowRoot.appendChild(css);
                            } else {
                                document.body.appendChild(css);
                            }
                        },
                    });

                    ReactDOM.render(element, mountPoint, function () {});
                    this._runCallback("connected", []);
                })();
            }
            disconnectedCallback() {
                this._runCallback("disconnected", []);
            }
            attributeChangedCallback(attributeName, oldValue, newValue, namespace) {
                this._runCallback("attributeChanged", [attributeName, oldValue, newValue, namespace]);
                if (!/^data\-.+$/.test(attributeName) && observedAttributes.indexOf("data-" + attributeName) !== -1) {
                    this._runCallback("attributeChanged", ["data-" + attributeName, oldValue, newValue, namespace]);
                }
            }
            adoptedCallback(oldDocument, newDocument) {
                this._runCallback("adopted", [oldDocument, newDocument]);
            }
        };

        customElements.define(tagName, proto);
    },
};
