var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import configurator from "core-js/configurator";
configurator({
    useNative: ["Promise"],
});
require("core-js/stable");
require("regenerator-runtime/runtime");
import ReactWebComponent from "./util/ReactWebComponent";
import styles from "./util/fonts.less";
var styleInjected = false;
function injectCoreStyles() {
    if (!styleInjected) {
        styles.use();
        styleInjected = true;
    }
}
if (typeof customElements.get("shop-mini-cart") === "undefined") {
    ReactWebComponent.create({
        elementFactory: function (props) { return __awaiter(void 0, void 0, void 0, function () {
            var component;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import(
                        /* webpackChunkName: "shop-mini-cart-loader" */ "./components/MiniCartWebComponent/webcomponent-loader")];
                    case 1:
                        component = (_a.sent()).component;
                        return [2 /*return*/, component(props)];
                }
            });
        }); },
        injectCoreStyles: injectCoreStyles,
        tagName: "shop-mini-cart",
        observedAttributes: ["data-language", "language"],
        useShadowDomWrapperDiv: true,
    });
}
if (typeof customElements.get("shop-product") === "undefined") {
    ReactWebComponent.create({
        elementFactory: function (props) { return __awaiter(void 0, void 0, void 0, function () {
            var component;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import(
                        /* webpackChunkName: "shop-product-loader" */ "./components/ProductWebComponent/webcomponent-loader")];
                    case 1:
                        component = (_a.sent()).component;
                        return [2 /*return*/, component(props)];
                }
            });
        }); },
        injectCoreStyles: injectCoreStyles,
        tagName: "shop-product",
        observedAttributes: ["data-sku", "data-language", "sku", "language"],
    });
}
if (typeof customElements.get("shop-toastr") === "undefined") {
    ReactWebComponent.create({
        elementFactory: function (props) { return __awaiter(void 0, void 0, void 0, function () {
            var component;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import(
                        /* webpackChunkName: "shop-toastr-loader" */ "./components/ToastrWebComponent/webcomponent-loader")];
                    case 1:
                        component = (_a.sent()).component;
                        return [2 /*return*/, component(props)];
                }
            });
        }); },
        injectCoreStyles: injectCoreStyles,
        tagName: "shop-toastr",
        observedAttributes: ["data-language", "language"],
    });
}
if (typeof customElements.get("shop-messages") === "undefined") {
    ReactWebComponent.create({
        elementFactory: function (props) { return __awaiter(void 0, void 0, void 0, function () {
            var component;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import(
                        /* webpackChunkName: "shop-messages-loader" */ "./components/MessagesWebComponent/webcomponent-loader")];
                    case 1:
                        component = (_a.sent()).component;
                        return [2 /*return*/, component(props)];
                }
            });
        }); },
        injectCoreStyles: injectCoreStyles,
        tagName: "shop-messages",
        observedAttributes: ["data-language", "language"],
    });
}
if (typeof customElements.get("shop-banner-product") === "undefined") {
    ReactWebComponent.create({
        elementFactory: function (props) { return __awaiter(void 0, void 0, void 0, function () {
            var component;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import(
                        /* webpackChunkName: "shop-product-loader" */ "./components/ProductBannerWebComponent/webcomponent-loader")];
                    case 1:
                        component = (_a.sent()).component;
                        return [2 /*return*/, component(props)];
                }
            });
        }); },
        injectCoreStyles: injectCoreStyles,
        tagName: "shop-banner-product",
        observedAttributes: ["data-sku", "data-language", "sku", "language"],
    });
}
