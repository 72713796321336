module.exports = function extractAttributes(nodeMap) {
    try {
        if (!nodeMap.attributes) {
            return {};
        }

        let attributes = {};
        for (let i = 0; i < nodeMap.attributes.length; i++) {
            const attr = nodeMap.attributes.item(i);
            attributes[attr.name] = attr.value;

            if (!/^data\-.+$/.test(attr.name)) {
                attributes["data-" + attr.name] = attr.value;
            }
        }

        return attributes;
    } catch (e) {
        return {};
    }
};
